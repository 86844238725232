/* globals md5 */

define('md5', [], function() {
  'use strict';

  return {
    'default': md5
  };
});

